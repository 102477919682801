<template>
  <div
    v-if="Object.keys(blogDetail).length"
    class="blog-wrapper"
  >
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <b-col
          cols="12"
          v-html="postConfig.headerHtml"
        >
        </b-col>
        <!-- blogs -->
        <b-col cols="12">
          <b-card body-class="p-0">
            <div
              class="blog-content"
              v-html="blogDetail.contents"
            />
            <!-- eslint-enable -->
          </b-card>
        </b-col>
        <!-- buttons -->
        <b-col
          cols="12"
          class="my-2"
        >
          <!-- 수정, 삭제-->
          <b-button
            v-if="userData.role === 'manager'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            :to="{
              name: 'menu-edit',
              params: { menuid: blogDetail.MenuId, postid: blogDetail.id },
            }"
          >
            수정
          </b-button>
        </b-col>
        <!--/ blogs -->
        <b-col
          cols="12"
          v-html="postConfig.footerHtml"
        >
        </b-col>
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->
  </div>
</template>

<script>
  import {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import { kFormatter } from '@core/utils/filter';
  // import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue';
  import router from '@/router';

  export default {
    components: {
      BFormInput,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      BBadge,
      BCardText,
      BDropdown,
      BForm,
      BDropdownItem,
      BFormTextarea,
      BFormCheckbox,
      BButton,
      // ContentWithSidebar,
    },
    directives: {
      Ripple,
    },
    data() {
      // content-header-title
      // console.log('bid: ', router.currentRoute.params.id);
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        search_query: '',
        blogDetail: [],
        postConfig: {},
      };
    },
    created() {
      this.getContent();
    },
    methods: {
      kFormatter,
      goMenuEdit() {},
      async getContent() {
        const postid = this.$route.params.id;
        this.$http.get(`/post/${postid}`).then(res => {
          // console.log(res.data);
          this.blogDetail = res.data.post;
          this.postConfig = res.data.config;
          // console.log(res.data.config);
          if (document.querySelector('.content-header-title')) {
            document.querySelector('.content-header-title').textContent =
              res.data.config.menuName;
          }
        });
      },
    },
    watch: {
      $route: function () {
        this.getContent();
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-blog.scss';
</style>
